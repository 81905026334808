<template>
  <div class="h-full">
    <MicrositeHeader />
    <MicrositeList />
  </div>
</template>

<script>
import MicrositeHeader from '@/components/Microsite/Header';
import MicrositeList from '@/components/Microsite/List';

export default {
  name: 'MicrositePage',
  components: {
    MicrositeHeader,
    MicrositeList,
  },
};
</script>
