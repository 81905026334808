<template>
  <main class="w-full px-[14px] py-[20px] bg-white rounded-lg mt-[10px]">
    <div class="flow-root">
      <ul
        role="list"
        class="flex flex-col gap-[10px]"
      >
        <li
          v-for="(item, index) in data"
          :key="index"
          class="flex items-center gap-[10px]"
          data-cy="j-site-list"
        >
          <span class="text-blue-gray-900 font-medium">
            {{ `${index + 1}.` }}
          </span>
          <div class="flex items-center justify-between w-full px-4 h-[52px] border border-gray-300 rounded-xl overflow-hidden">
            <div class="flex justify-center items-center gap-4 overflow-hidden">
              <div class="w-[34px] h-[34px] overflow-hidden">
                <img
                  :src="item.favicon || item.logo"
                  :alt="item.name"
                  class="w-full h-full object-cover"
                >
              </div>
              <p
                class="truncate text-sm font-medium text-blue-gray-800 flex-1"
                data-cy="j-site-list__name"
                :title="item.name"
              >
                {{ item.name }}
              </p>
              <span class="px-3 py-[2px] text-xs bg-gray-100 text-gray-700 rounded-lg">
                {{ formatDate(item.updated_at) }}
              </span>
            </div>
            <div class="flex justify-center items-center gap-4">
              <BaseButton
                class="text-green-700 font-bold text-sm border-none"
                data-cy="j-site-list__button-manage"
                @click="onManage(item.id)"
              >
                <template #icon-left>
                  <SettingIcon class="w-5 h-5 fill-green-700" />
                </template>
                <p>Kelola</p>
              </BaseButton>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import { formatDate } from '@/common/helpers/date';
import BaseButton from '@/common/components/BaseButton';
import SettingIcon from '@/assets/icons/setting.svg?inline';
import { RepositoryFactory } from '@/repositories/microsite/RepositoryFactory';

const settingsRepository = RepositoryFactory.get('settings');

export default {
  name: 'MicrositeList',
  components: {
    BaseButton,
    SettingIcon,
  },
  data() {
    return {
      formatDate,
      data: [],
    };
  },
  async mounted() {
    await this.fetchSites();
  },
  methods: {
    async fetchSites() {
      try {
        const response = await settingsRepository.getSites();
        const { data } = response.data;
        this.data = data;
      } catch (error) {
        this.$toast({
          type: 'error',
          message: 'Gagal mendapatkan data list site, silakan coba beberapa saat lagi',
        });
      }
    },
    async onManage(siteId) {
      try {
        const response = await this.$store.dispatch('microsite/generateJSiteUrl', siteId);
        const { url } = response.data || {};

        if (url) {
          window.location.href = url;
        }
      } catch (error) {
        // Token Expired
        if (error.response.status === 400) {
          try {
            await this.$store.dispatch('microsite/refreshJSiteToken');

            this.onManage(siteId);
          } catch (err) {
            this.$toast({
              type: 'error',
              message: 'Mohon maaf, terjadi kesalahan. Mohon coba beberapa saat lagi.',
            });

            console.error(err);
          }
        } else {
          this.$toast({
            type: 'error',
            message: 'Gagal membuka halaman kelola J-Site Anda. Mohon coba beberapa saat lagi.',
          });
        }
      }
    },
  },
};
</script>
