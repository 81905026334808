<template>
  <div class="header-microsite bg-white rounded-lg flex justify-between px-6">
    <div class="py-[30px]">
      <div class="py-[25px] px-[10px] flex flex-col gap-[10px]">
        <h1 class="text-blue-gray-900 font-bold text-2xl capitalize">
          Mengelola website dengan cepat dan mudah
        </h1>
        <p class="text-gray-600 font-roboto">
          kamu dapat mengelola informasi website disini
        </p>
        <LinkButton
          v-if="$hasPermission('j-site.site-management.create')"
          href="j-site/tambah"
          title="Create new site"
          class="bg-green-700 hover:bg-green-600 text-sm text-white w-[171px] mt-[15px]"
          data-cy="j-site-header__button-add"
        >
          <JdsIcon
            name="plus"
            size="16px"
            fill="#fff"
          />
          Tambah J-Site
        </LinkButton>
      </div>
    </div>
    <div class="pr-[30px]">
      <img
        src="@/assets/images/microsite-header.gif"
        alt="Ilustrasi Mengelola Microsite"
        class="animated-gif"
        width="250"
        height="250"
      >
    </div>
  </div>
</template>

<script>
import LinkButton from '@/common/components/LinkButton';

export default {
  name: 'MicrositeHeader',
  components: {
    LinkButton,
  },
};
</script>

<style scoped>
.header-microsite {
  background-image: url('~@/assets/images/pattern-microsite-content.svg');
  background-position: left bottom -60px;
  background-repeat: no-repeat;
}

img.animated-gif{
  width: auto;
  height: 250px;
}
</style>
